import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

type AppConfig<T> = {
  [key: string]: T;
};

@Injectable()
export class AppRuntimeConfigService {
  private appConfig: AppConfig<boolean | string | any>;

  constructor(private http: HttpClient) {}

  loadAppConfig(): Observable<any> {
    return this.http
      .get<AppConfig<boolean | string | any>>('runtimeConfig.json', {
        headers: {
          'Cache-Control': 'no-cache',
        },
      })
      .pipe(
        map((response) => {
          this.appConfig = response;
        }),
        catchError((error) => {
          console.log('Error (loadAppConfig): ', error.message);
          return throwError(() => error);
        })
      );
  }

  getConfig() {
    return this.appConfig;
  }
}
