import { environment } from '../../environments/environment';
import { AmountGetString, PM_ACTIONS, inIframe } from './common.model';

export namespace GooglePayNamespace {
  export class UI {
    paynetWindow = window.parent as Window;
    buttonColor: buttonColor = buttonColor.BLACK;
    buttonType: buttonType = buttonType.BUY;
    buttonSizeMode: buttonSizeMode = buttonSizeMode.FILL;
    Width: number = 360;
    Height: number = 58;
    cssroot: any;
    InProgress: boolean = true;
    get styleString() {
      return `width: ${this.cssroot.width.value};height: ${this.cssroot.height.value}`;
    }
    constructor() {
      this.initStyles();
    }

    private resize() {
      //console.log('window.innerWidth', window.innerWidth);
      if (window.innerWidth < 781) {
        this.cssroot = {
          width: {
            var: '--pp-googlepay-width',
            value: `100%`,
          },
          height: {
            var: '--pp-googlepay-height',
            value: `${this.Height}px`,
          },
        };
      } else {
        this.Width = 360;
        this.cssroot = {
          width: {
            var: '--pp-googlepay-width',
            value: `${this.Width}px`,
          },
          height: {
            var: '--pp-googlepay-height',
            value: `${this.Height}px`,
          },
        };
      }
    }

    initStyles() {
      this.resize();
      this.postMessage(PM_ACTIONS.GOOGLEPAY_RENDER_STYLE);
    }

    postMessage(action: PM_ACTIONS, obj?: any) {
      if (inIframe()) {
        console.log(action);
        switch (action) {
          // case PM_ACTIONS.PSP_VGS_LOADED:
          // case PM_ACTIONS.PSP_READY:
          //   this.paynetWindow.postMessage(
          //     {
          //       action: action,
          //     },
          //     environment.paynetmdURL
          //   );
          //   break;

          // case PM_ACTIONS.PSP_EXCEPTION:
          // case PM_ACTIONS.PSP_TOKENIZED:
          // case PM_ACTIONS.PSP_LINKEDCARDS_SET_CARDTOKEN:
          // case PM_ACTIONS.PSP_LINKEDCARDS_REMOVE_CARD_POPUP:
          // case PM_ACTIONS.PSP_VALIDATE_OUT:
          //   this.paynetWindow.postMessage(
          //     {
          //       action: action,
          //       state: obj,
          //     },
          //     environment.paynetmdURL
          //   );
          //   break;
          case PM_ACTIONS.GOOGLEPAY_VIEWINIT:
          case PM_ACTIONS.GOOGLEPAY_FREEZE:
          case PM_ACTIONS.GOOGLEPAY_UNFREEZE:
            this.paynetWindow.postMessage(
              {
                action,
              },
              environment.paynetmdURL
            );
            break;
          case PM_ACTIONS.GOOGLEPAY_RENDER_STYLE:
            this.paynetWindow.postMessage(
              {
                action,
                state: this.cssroot,
              },
              environment.paynetmdURL
            );
            break;
          case PM_ACTIONS.GOOGLEPAY_ISREADYTOPAY:
          case PM_ACTIONS.GOOGLEPAY_TOKENIZED:
            this.paynetWindow.postMessage(
              {
                action,
                state: obj,
              },
              environment.paynetmdURL
            );
            break;
        }
      } else if (!environment.production) this.InProgress = false;
    }
  }

  export class DATA {
    environment: envGPay;
    PaymentDataRequest: PaymentDataRequest = new PaymentDataRequest();
    ExternalPaymentRequest: ExternalPaymentRequest;

    PaymentDataResponse: any;
    constructor() {
      this.environment = environment.production
        ? envGPay.PRODUCTION
        : envGPay.TEST;
    }
  }

  // export function WS2MPI_MOCK(paymentToken, amount, currency, url, merchant) {
  //   return {
  //     merchant: merchant,
  //     amount: amount,
  //     currencyCode: currency,
  //     returnUrl: url,
  //     paymentToken: {
  //       ...JSON.parse(paymentToken),
  //     },
  //   };
  // }

  export function WS2MPI_OUT(
    PaymentDataResponse: IPaymentDataResponse,
    ExternalPaymentRequest: ExternalPaymentRequest
  ) {
    return {
      opid: ExternalPaymentRequest.opid,
      issuer: ExternalPaymentRequest.issuer,
      checkoutcurrency: ExternalPaymentRequest.checkoutcurrency,
      customerInfo: {
        FirstName: (
          PaymentDataResponse.paymentMethodData.info.billingAddress.name ?? ''
        ).split(' ')[0],
        LastName: (
          PaymentDataResponse.paymentMethodData.info.billingAddress.name ?? ''
        )
          .split(' ')
          .slice(1)
          .join(' '),
        Email: PaymentDataResponse.email,
        Gsm: (
          PaymentDataResponse.paymentMethodData.info.billingAddress
            .phoneNumber ?? ''
        )
          .split(' ')
          .join(''),
      },
      paymentToken: {
        ...JSON.parse(
          PaymentDataResponse.paymentMethodData.tokenizationData.token
        ),
      },
    };
  }

  export class ExternalPaymentRequest {
    amount: number;
    checkoutcurrency: number;
    issuer: number;
    opid: number;
    merchant: string;
    merchantId: string;
    gateway: string;
    gatewayMerchantId: string;
    constructor(IN: any) {
      this.opid = IN.opid;
      this.issuer = IN.issuer;
      this.checkoutcurrency = IN.checkoutcurrency;
      this.amount = IN.amount;
      IN.paramInfo.forEach((element) => {
        switch (element.Key) {
          case 'GPayMerchantName':
            this.merchant = element.Value;
            break;

          case 'GPayMerchantId':
            this.merchantId = element.Value;
            break;

          case 'GPayGateway':
            this.gateway = element.Value;
            break;

          case 'GPayGatewayMerchantId':
            this.gatewayMerchantId = element.Value;
            break;
          // case 'GPayReturnUrl': {
          //   this.ReturnUrl = IN.Value;
          //   break;
          // }
          // case 'GPayMerchant': {
          //   this.Merchant = IN.Value;
          //   break;
          // }
        }
      });
    }
  }

  export enum envGPay {
    TEST = 'TEST',
    PRODUCTION = 'PRODUCTION',
  }

  export enum buttonType {
    BUY = 'buy',
    PLAIN = 'plain',
    DONATE = 'donate',
  }

  export enum buttonColor {
    DEFAULT = 'default',
    BLACK = 'black',
    WHITE = 'white',
  }

  export enum buttonSizeMode {
    FILL = 'fill',
    STATIC = 'static',
  }
  ////////////////////////////PaymentDataRequest/////////////////////

  export class PaymentDataRequest implements IPaymentDataRequest {
    apiVersion: number;
    apiVersionMinor: number;
    allowedPaymentMethods: IallowedPaymentMethods[];
    transactionInfo: ItransactionInfo;
    merchantInfo: ImerchantInfo;
    emailRequired: boolean;

    initPaymentDataRequest(IN: ExternalPaymentRequest) {
      this.merchantInfo.merchantName = IN.merchant;
      this.merchantInfo.merchantId = IN.merchantId;
      this.allowedPaymentMethods[0].tokenizationSpecification.parameters.gateway =
        IN.gateway;
      this.allowedPaymentMethods[0].tokenizationSpecification.parameters.gatewayMerchantId =
        IN.gatewayMerchantId;
      this.patchTransactionInfo(IN);

      console.log('PaymentDataRequest', this);
    }

    patchPaymentDataRequest(IN: ExternalPaymentRequest) {
      this.patchTransactionInfo(IN);

      console.log('PaymentDataRequest', this);
    }

    private patchTransactionInfo(IN) {
      this.transactionInfo.totalPrice = AmountGetString(IN.amount);
      this.transactionInfo.currencyCode =
        IN.checkoutcurrency == 498
          ? 'MDL'
          : IN.checkoutcurrency == 840
          ? 'USD'
          : IN.checkoutcurrency == 978
          ? 'EUR'
          : '';
    }

    constructor() {
      this.apiVersion = 2;
      this.apiVersionMinor = 0;
      this.allowedPaymentMethods = [
        {
          type: 'CARD',
          parameters: {
            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
            allowedCardNetworks: ['MASTERCARD'], // ["AMEX", "DISCOVER", "INTERAC", "JCB", "MASTERCARD", "VISA"]
            assuranceDetailsRequired: true,
            billingAddressRequired: true,
            billingAddressParameters: {
              format: 'FULL',
              phoneNumberRequired: true,
            },
          },
          tokenizationSpecification: {
            type: 'PAYMENT_GATEWAY',
            parameters: {
              gateway: 'ukrcardpay',
              gatewayMerchantId: 'googletest',
            },
          },
        },
      ];
      this.transactionInfo = {
        totalPriceStatus: 'FINAL',
        totalPriceLabel: 'Total',
        totalPrice: '100.00',
        currencyCode: 'MDL',
        countryCode: 'MD',
      };
      this.merchantInfo = {
        merchantName: 'Paynet Services LLC',
        merchantId: 'BCR2DN4T4273BCKR',
      };
      this.emailRequired = true;
    }
  }

  interface IPaymentDataRequest {
    apiVersion: number;
    apiVersionMinor: number;
    allowedPaymentMethods: IallowedPaymentMethods[];
    transactionInfo: ItransactionInfo;
    merchantInfo: ImerchantInfo;
    emailRequired?: boolean;
  }

  interface IallowedPaymentMethods {
    type: string;
    parameters: ICardParameters;
    tokenizationSpecification: ItokenizationSpecification;
  }

  interface ICardParameters {
    allowedAuthMethods: string[];
    allowedCardNetworks: string[];
    assuranceDetailsRequired?: boolean;
    billingAddressRequired?: boolean;
    billingAddressParameters?: IbillingAddressParameters;
  }

  interface IbillingAddressParameters {
    format?: string; //'MIN',
    phoneNumberRequired?: boolean;
  }

  interface ItokenizationSpecification {
    type: string;
    parameters: ItokenizationSpecification_parameters;
  }

  interface ItokenizationSpecification_parameters {
    gateway: string;
    gatewayMerchantId: string;
  }

  interface ItransactionInfo {
    totalPriceStatus: string;
    totalPriceLabel: string;
    totalPrice: string;
    currencyCode: string;
    countryCode?: string;
  }
  interface ImerchantInfo {
    merchantName: string;
    merchantId: string;
  }
  ////////////////////////////PaymentDataRequest/////////////////////

  ////////////////////////////PaymentDataResponse/////////////////////
  export class PaymentDataResponse implements IPaymentDataResponse {
    email: string;
    paymentMethodData: IpaymentMethodData;
    constructor(IN: IPaymentDataResponse) {
      this.email = IN.email;
      this.paymentMethodData = IN.paymentMethodData;
    }
  }

  /*
  {
    "apiVersion": 2,
    "apiVersionMinor": 0,
    "email": "i.antropov@paynet.md",
    "paymentMethodData": {
        "description": "Test Card: Mastercard •••• 4444",
        "info": {
            "assuranceDetails": {
                "accountVerified": true,
                "cardHolderAuthenticated": false
            },
            "billingAddress": {
                "address1": "1600 Amphitheatre Parkway",
                "address2": "",
                "address3": "",
                "administrativeArea": "CA",
                "countryCode": "US",
                "locality": "Mountain View",
                "name": "Card Holder Name",
                "phoneNumber": "6505555555",
                "postalCode": "94043",
                "sortingCode": ""
            },
            "cardDetails": "4444",
            "cardNetwork": "MASTERCARD"
        },
        "tokenizationData": {
            "token": "{\"signature\":\"MEUCIQC0z9Wq3jl4jWQJuTmNoWrCv9bGmw8XJoYJIccwIbUJyAIgEPrN6/pXIHn6MxR4G1xf15d3no5Qj1X/CMuHO9zTrq0\\u003d\",\"intermediateSigningKey\":{\"signedKey\":\"{\\\"keyValue\\\":\\\"MFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAE4k4EdEC0TPTiEy1wf9gQkf8M2kS8djjCeHPQThI6MvwgBhIckwj8c9ABbb5Jd0WOdVIEFcQfEYSOybNjRIu0Cg\\\\u003d\\\\u003d\\\",\\\"keyExpiration\\\":\\\"1705028591577\\\"}\",\"signatures\":[\"MEYCIQD+dpKtIf9F5coQ4++WvmikQdm/Z6Y41gPbtZQvLSr0kAIhAIgHaCYMzVjYTPWcspDqYYBQ43VOvbMEP1lMGuWat229\"]},\"protocolVersion\":\"ECv2\",\"signedMessage\":\"{\\\"encryptedMessage\\\":\\\"bZOXdTkBLoExKLeP7trdn4UiYWoL3pSRjz53oGsK39LlZu05gDGSEaa19MbXxjGY5plvbro29eTXoEPArbn0AVdvRG94s8GqoU2aWM82PMuQpNedttSts12k9k0B8xmH5GAIRqMqHD8+dXV5+ING/1tUiqcyy1h0WV5tMS4wiwAx0GXpXrgK007lLbRDdXtdW9rCcgtCjBdlTY4vY1jlQkB1rdHGvhTqImhmXzjcsQ/N1xKBwtnpbCtzNgLxW9aeNhYwp1hCm+bRouKuIDiCG+68IDkhi7qD3XCdbfNkjkRSSQO6czSxhJyjn9ki39a9TpqbZhroxqrR/1SsKlB/Q9L4D3iRCWm9jYPMgFOnhp38Hk79FFHQVKqBJBbDmSMrDduRlcB7X6oVQAjxeAgPdVF60huVw3hP2Z8jOQMSa/aK+Hxa/ZrVNEUKNcWahT76dYAE1e1dupqP+2gOzcgFNQpIWtwJjin7w1GUvPIQJ6knmhCrCVB0iZifLRjBK+uRVDYP1Zf2iTNxjWJA52lXjGrndtCCfUDcdOFxM+/vWRCKs+VwuXQP6IBOzuQdLdQD4AnscduS9lrT\\\",\\\"ephemeralPublicKey\\\":\\\"BPYAESiQ+JLMd8Yrch+FNd+mO6UviKAJaYul/QFO7hU527B60H21qpDFWKNbJmzo5agQ/MX7J7hLDODvVRgUnMY\\\\u003d\\\",\\\"tag\\\":\\\"KlMUabFetakFyR01w3dCI0CI315b1HFpi2+ucdSCYEM\\\\u003d\\\"}\"}",
            "type": "PAYMENT_GATEWAY"
        },
        "type": "CARD"
    }
}
  */
  interface IPaymentDataResponse {
    email: string;
    paymentMethodData: IpaymentMethodData;
  }

  interface IpaymentMethodData {
    info: Iinfo;
    tokenizationData: ItokenizationData;
  }

  interface ItokenizationData {
    token: string;
    type: string;
  }

  interface Iinfo {
    billingAddress: IbillingAddress;
  }

  interface IbillingAddress {
    address1?: string;
    address2?: string;
    address3?: string;
    administrativeArea?: string;
    countryCode?: string;
    locality?: string;
    name?: string;
    phoneNumber?: string;
    postalCode?: string;
    sortingCode?: string;
  }
  ////////////////////////////PaymentDataResponse/////////////////////
}
