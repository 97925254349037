import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { GooglePayNamespace } from '../models/google-pay.model';
import { environment } from '../../environments/environment';
import { PM_ACTIONS } from '../models/common.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements AfterViewInit {
  private static Zone = GooglePayNamespace;
  UI = new AppComponent.Zone.UI();
  DATA = new AppComponent.Zone.DATA();
  // amount: number;
  // currency: number;
  // opid: number;
  // issuer: string;
  // //paramInfo: GooglePayNamespace.ParamInfo;
  // url: string;
  // merchant: string;

  constructor() {
    // console.log(this.UI);
    // console.log(this.DATA);
  }

  ngAfterViewInit(): void {
    this.UI.postMessage(PM_ACTIONS.GOOGLEPAY_VIEWINIT);
  }

  onReadyToPayChange(event) {
    console.log('EVENT', event);
    this.UI.postMessage(
      PM_ACTIONS.GOOGLEPAY_ISREADYTOPAY,
      !environment.skipOnReadyToPayChange ? event.detail.isButtonVisible : true
    );
  }

  onLoadPaymentData(event) {
    console.log('load payment data', event.detail);
    if (event.detail.paymentMethodData.tokenizationData.token)
      console.log(
        '================================WS2MPI==================================='
      );
    // this.DATA.PaymentDataResponse = GooglePayNamespace.WS2MPI_MOCK(
    //   event.detail.paymentMethodData.tokenizationData.token,
    //   this.amount,
    //   this.currency,
    //   this.url,
    //   this.merchant
    // );

    const IN = new GooglePayNamespace.PaymentDataResponse(event.detail);

    this.DATA.PaymentDataResponse = GooglePayNamespace.WS2MPI_OUT(
      IN,
      this.DATA.ExternalPaymentRequest
    );
    //console.log('event', event);
    this.UI.postMessage(
      PM_ACTIONS.GOOGLEPAY_TOKENIZED,
      this.DATA.PaymentDataResponse
    );
  }

  clickCallback(event) {
    this.UI.postMessage(PM_ACTIONS.GOOGLEPAY_FREEZE);
  }

  errorCallback(event) {
    console.log(event);
    this.UI.postMessage(PM_ACTIONS.GOOGLEPAY_UNFREEZE);
  }

  cancelCallback(event) {
    //console.log(event);
    this.UI.postMessage(PM_ACTIONS.GOOGLEPAY_UNFREEZE);
  }

  paymentDataChangedCallback(event) {
    // this.UI.postMessage(PM_ACTIONS.GOOGLEPAY_FREEZE);
    // this.UI.postMessage(PM_ACTIONS.GOOGLEPAY_UNFREEZE);
  }

  @HostListener('window:message', ['$event'])
  onPostMessage(event: MessageEvent) {
    if (event.origin === environment.paynetmdURL) {
      if (!!event.data) {
        switch (event.data.action) {
          case PM_ACTIONS.GOOGLEPAY_PAYMENT_OBJ:
            console.log(event.data);
            this.DATA.ExternalPaymentRequest =
              new GooglePayNamespace.ExternalPaymentRequest(event.data.state);
            this.DATA.PaymentDataRequest.initPaymentDataRequest(
              this.DATA.ExternalPaymentRequest
            );
            console.log(this.DATA);
            this.UI.InProgress = false;
            break;
          case PM_ACTIONS.GOOGLEPAY_PATCH_PAYMENT_OBJ:
            console.log(event.data);
            this.DATA.ExternalPaymentRequest =
              new GooglePayNamespace.ExternalPaymentRequest(event.data.state);
            // this.DATA.PaymentDataRequest.patchTransactionInfo(event.data.state);
            this.DATA.PaymentDataRequest.patchPaymentDataRequest(
              this.DATA.ExternalPaymentRequest
            );
            console.log(this.DATA);
            console.log('this.DATA', this.DATA);

            break;
        }
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.UI.Height = (
      document.querySelector('html') as HTMLElement
    ).getBoundingClientRect().height;
    this.UI.Width = (
      document.querySelector('app-root') as HTMLElement
    ).getBoundingClientRect().width;
    this.UI.initStyles();
  }
}
