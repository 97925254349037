import { Observable } from 'rxjs';

export enum PM_ACTIONS {
  GOOGLEPAY_RENDER_STYLE = 'GOOGLEPAY-RENDER-STYLE',
  GOOGLEPAY_TOKENIZED = 'GOOGLEPAY-TOKENIZED',
  GOOGLEPAY_PAYMENT_OBJ = 'GOOGLEPAY-PAYMENT-OBJ',
  GOOGLEPAY_PATCH_PAYMENT_OBJ = 'GOOGLEPAY-PATCH-PAYMENT-OBJ',
  GOOGLEPAY_VIEWINIT = 'GOOGLEPAY-VIEWINIT',
  GOOGLEPAY_ISREADYTOPAY = 'GOOGLEPAY-ISREADYTOPAY',
  GOOGLEPAY_FREEZE = 'GOOGLEPAY-FREEZE',
  GOOGLEPAY_UNFREEZE = 'GOOGLEPAY-UNFREEZE',
}

export function waitForElementToAppear(element, _fn?) {
  return new Observable(function (observer) {
    var el_ref;
    let intervalId;

    var f = () => {
      el_ref = document.querySelector(element);
      // if (elementId === 'SenderCardNr') {
      //   console.log('==============================>>>>>>>>> SenderCardNr');
      // }
      if (el_ref) {
        clearInterval(intervalId);
        observer.next({ el_ref, _fn } as any);
        observer.complete();
        return;
      }

      //window.requestAnimationFrame(f);
      intervalId = setInterval(f, 100);
    };
    f();
  });
}

export const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export function AmountGetString(pAmount): string {
  return evenRound(parseFloat((pAmount / 100).toString())).toFixed(2);
}
export function AmountGetAsLong(amountStr) {
  amountStr = ['', ',', '.'].includes(amountStr) ? '0.00' : amountStr;
  amountStr = amountStr.replace(',', '.');
  var amountRaw = parseFloat(amountStr).toFixed(2);
  return parseInt(evenRound(Number(amountRaw) * 100).toString());
}
export function FormatAmountString(pAmount): string {
  const intl = Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return intl.format(pAmount);
}
function evenRound(num, decimalPlaces = 2) {
  var d = decimalPlaces || 0;
  var m = Math.pow(10, d);
  var n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
  var i = Math.floor(n),
    f = n - i;
  var e = 1e-8; // Allow for rounding errors in f
  var r = f > 0.5 - e && f < 0.5 + e ? (i % 2 == 0 ? i : i + 1) : Math.round(n);
  return d ? r / m : r;
}
