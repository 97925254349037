import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { GooglePayButtonModule } from '@google-pay/button-angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppRuntimeConfigService } from '../services/app-config.service';
import { HttpClientModule } from '@angular/common/http';

const appInitializerFn = (appConfig: AppRuntimeConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    GooglePayButtonModule,
  ],
  providers: [
    AppRuntimeConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppRuntimeConfigService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
